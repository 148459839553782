<template>
  <li @click="log" class="col-span-1 bg-white rounded-lg border border-gray-200 shadow divide-y divide-gray-200 overflow-hidden flex flex-col justify-between">
    <div class="w-full divide-y">
      <div class="w-full p-6 space-y-1">
        <div v-for="(entry, index) in serviceData" :key="index" class="flex items-start space-x-3" :class="entry.label === 'Instalator: ' && 'pt-4' ">
          <p class="w-1/3 text-gray-500 text-sm">{{ entry.label }}</p>
          <h3 v-html="entry.value" class="w-2/3 text-right text-gray-900 text-sm font-medium"></h3>
        </div>
      </div>
      <div class="flex items-center divide-x divide-gray-200 mt-4 px-6">
        <div class="w-3/5 flex justify-between items-center py-6 pr-4">
          <p class="text-sm font-medium text-gray-700">
            {{$t("service.status")}}:
          </p>
          <span v-if="service.status === 'done'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            {{$t("service.done")}}
          </span>
          <span v-if="service.status === 'rejected'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            {{$t("service.rejected")}}
          </span>
          <span v-else-if="service.status === 'require_spare_parts'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            {{$t("service.requireParts")}}
          </span>
          <span v-else-if="service.status === 'spare_parts_sent'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            {{$t("service.sentParts")}}
          </span>
        </div>
        <div class="w-2/5 flex items-center justify-between py-6 pl-4">
          <p class="text-sm font-medium text-gray-700">
            {{$t("home.inspections")}}:
          </p>
          <ButtonDropdownInspectionTable :device-id="serviceDevice.id" :custom-device="serviceDevice.deviceTemplate != null" />
        </div>
      </div>
      <div class="w-full p-6">
        <label for="note" class="block text-sm font-medium text-gray-700">
          {{$t("service.faultDescription")}}
        </label>
        <div class="mt-1 relative">
          <p class="text-sm">{{ service.issueDescription }}</p>
        </div>
      </div>
      <div v-if="service.sparePartsDescription" class="w-full p-6">
        <label for="note" class="block text-sm font-medium text-gray-700">
          {{$t("service.requiredPartsDescription")}}
        </label>
        <div class="mt-1 relative">
          <p class="text-sm">{{ service.sparePartsDescription }}</p>
        </div>
        <div v-if="service.sparePartsSentDate">
          <p class="text-sm mt-4">{{$t("service.sparePartsSent")}}: <span class="font-medium text-gray-700">{{ new Date(service.sparePartsSentDate).toLocaleDateString('pl-PL') }}</span></p>
        </div>
      </div>
      <div v-if="service.rejectionReason" class="w-full p-6">
        <label for="note" class="block text-sm font-medium text-gray-700">
          {{$t("service.rejectionReason")}}
        </label>
        <div class="mt-1 relative">
          <p class="text-sm">{{ service.rejectionReason }}</p>
        </div>
        <div v-if="service.sparePartsSentDate">
          <p class="text-sm mt-4">{{$t("service.sparePartsSent")}}: <span class="font-medium text-gray-700">{{ new Date(service.sparePartsSentDate).toLocaleDateString('pl-PL') }}</span></p>
        </div>
      </div>
      <div v-if="service.paramsDiff && service.paramsDiff.length" class="w-full p-6">
        <label for="note" class="block text-sm font-medium text-gray-700">
          {{ $t("service.deviceParametersChangedLabel") }}
        </label>
        <div class="mt-2">
          <div class="flex text-xs font-bold border-b border-gray-300 divide-x divide-gray-300">
            <p class="w-1/3 pr-1">{{ $t("pdf.technical.parameter") }}</p>
            <p class="w-1/3 px-1">{{ $t("pdf.technical.oldValue") }}</p>
            <p class="w-1/3 pl-1">{{ $t("pdf.technical.newValue") }}</p>
          </div>
          <div v-for="param in service.paramsDiff" :key="param.name" class="flex text-sm divide-x divide-gray-300">
            <p class="w-1/3 pr-1">{{ param.label }}</p>
            <p class="w-1/3 px-1">{{ param.oldValue }}</p>
            <p class="w-1/3 pl-1">{{ param.value }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full divide-y divide-gray-200">
      <div class="w-full p-6 space-y-1 divide-y divide-gray-200">
        <a v-for="(entry, index) in serviceDownloadables" :key="index" :href="entry.value" target="_blank" class="pt-2 pb-1 text-sm font-medium text-gray-900 w-full flex justify-between">
          {{ entry.label }}
          <ArrowDownTrayIcon class="w-4 h-4" />
        </a>
      </div>
      <div>
        <div class="-mt-px flex divide-x divide-gray-200">
          <div v-if="service.status === 'require_spare_parts'" class="w-0 flex-1 flex bg-ifgreen" :title="$t('confirm')">
            <button @click="sendSpareParts" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg hover:bg-ifgreen-dark">
              <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
            </button>
          </div>
          <div v-if="service.status === 'spare_parts_sent'" class="w-0 flex-1 flex bg-ifgreen" :title="$t('confirm')">
            <button :title="$t('service.finishService')" @click="moveToDone" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg hover:bg-ifgreen-dark">
              <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
            </button>
          </div>
          <div class="w-0 flex-1 flex" :title="$t('installation.instalatorEmail')">
            <a :href="`mailto:${service.installer.email}`" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
              <EnvelopeIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
            </a>
          </div>
          <div class="-ml-px w-0 flex-1 flex" :title="$t('installation.instalatorPhone')">
            <a :href="`tel:${service.installer.phone}`" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              <PhoneIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
            </a>
          </div>
          <div v-if="service.status === 'require_spare_parts'" class="w-0 flex-1 flex bg-red-500" :title="$t('reject')">
            <button @click="rejectService" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg hover:bg-red-600">
              <XMarkIcon class="w-5 h-5 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { ExclamationTriangleIcon, EnvelopeIcon, PhoneIcon, XMarkIcon, CheckIcon, ArrowDownTrayIcon, ArrowLeftIcon, TrashIcon } from '@heroicons/vue/24/solid'
import ButtonDropdownInspectionTable from '@/components/admin/ButtonDropdownInspectionTable.vue'

export default {
  name: "AdminServiceCard",
  components: {
    EnvelopeIcon,
    PhoneIcon,
    ArrowDownTrayIcon,
    ButtonDropdownInspectionTable,
    XMarkIcon,
    CheckIcon,
    ArrowLeftIcon,
    ExclamationTriangleIcon,
    TrashIcon
  },
  emits: ['resolve-service'],
  props: {
    service: Object
  },
  data () {
    return {}
  },
  computed: {
    serviceDevice () {
      return this.service.customDevice
    },
    serviceData () {
      return [
      {label: `${this.$t("installation.device")}`, value: `<a target="_blank" class="underline hover:no-underline" href="/admin/devices/${this.serviceDevice.id}${this.serviceDevice.deviceTemplate ? '?customDevice=true' : ''}" >${this.serviceDevice.deviceTemplate ? this.serviceDevice.deviceTemplate.name : this.serviceDevice.id}</a>`},
        {label: `${this.$t("service.started")}: `, value: new Date(this.service.serviceStartDate).toLocaleDateString('pl-PL')},
        {label: `${this.$t("service.finished")}: `, value: this.service.serviceEndDate ? new Date(this.service.serviceEndDate).toLocaleDateString('pl-PL') : null},
        {
          label: `${this.$t("installation.installationAddress")} `,
          value: this.serviceDevice.installation ?
           `${this.serviceDevice.installation.customerAddress} <br />
            ${this.serviceDevice.installation.customerCity} <br />
            ${this.serviceDevice.installation.customerZipcode}` : ''
        },
        {label: `${this.$t("installation.customer")} `, value: this.serviceDevice.installation ? this.serviceDevice.installation.customerName : ''},
        {label: `${this.$t("installation.customerPhone")} `, value: this.serviceDevice.installation ? this.serviceDevice.installation.customerPhone : ''},
        {label: `${this.$t("service.serviceman")}: `, value: this.service.installer.name},
        {label: `${this.$t("installation.company")} `, value: this.service.installer.company},
        {label: `${this.$t("installation.email")} `, value: this.service.installer.email},
        {label: `${this.$t("service.servicemanPhone")}: `, value: this.service.installer.phone}
      ]
    },
    serviceDownloadables () {
      const downloadables = [
        {label: `${this.$t("service.serviceCard")}`, value: this.service.serviceCard},
        {label: `${this.$t("service.issueImage1")}`, value: this.service.issue1Image},
        {label: `${this.$t("service.issueImage2")}`, value: this.service.issue2Image},
        {label: `${this.$t("service.fixedImage1")}`, value: this.service.fixed1Image},
        {label: `${this.$t("service.fixedImage2")}`, value: this.service.fixed2Image},
      ]
      return downloadables.filter(item => {return item.value})
    }
  },
  methods: {
    log () {
      console.log(this.service)
    },
    sendSpareParts () {
      this.$emit('resolve-service', {
        service: this.service,
        action: 'send_spare_parts',
        title: `${this.$t('service.sendSparePartsTitle')}`,
        text: `${this.$t('service.sendSparePartsText')}`
      })
    },
    rejectService () {
      this.$emit('resolve-service', {
        service: this.service,
        action: 'rejected',
        title: `${this.$t('service.rejectServiceTitle')}`,
        text: `${this.$t('service.rejectServiceText')}`
      })
    },
    moveToDone () {
      this.$emit('resolve-service', {
        service: this.service,
        action: 'done',
        title: `${this.$t('service.moveToDoneTitle')}`,
        text: `${this.$t('service.moveToDoneText')}`
      })
    }
  }
}
</script>
