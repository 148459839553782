<template>
  <Menu as="div" class="relative inline-block text-left ml-1">
    <div>
      <MenuButton :title="$t('device.parameters.history')" class="flex items-center text-gray-400 px-1 py-1 hover:text-gray-500 focus:outline-none">
        <span class="sr-only">Open parameters history</span>
        <ArchiveBoxIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="w-80 z-10 origin-top-right absolute right-0 mt-2 rounded-md overflow-hidden shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div v-if="parameters">
          <div class="flex bg-gray-50">
            <p class="w-1/2 px-2 py-2 text-left text-xs text-gray-500">
              {{ $t('device.parameters.value') }}
            </p>
            <p class="w-1/2 px-2 py-2 text-right text-xs text-gray-500">
              {{ $t('device.parameters.createdAt') }}
            </p>
          </div>
          <div v-for="(param, index) in parameters" :key="index" class="flex" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
            <p class="w-1/2 px-2 py-2 whitespace-nowrap text-sm text-gray-500">{{ param.value }}</p>
            <p class="w-1/2 px-2 py-2 whitespace-nowrap text-sm text-gray-500 text-right">{{ new Date(param.createdAt).toLocaleString('pl-PL') }}</p>
          </div>
        </div>
        <div v-else class="flex align-center justify-center">
          <CogIcon class="h-12 w-12 text-ifgreen animate-spin" aria-hidden="true" />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { CogIcon } from '@heroicons/vue/24/outline'
import { ArchiveBoxIcon } from '@heroicons/vue/24/solid'

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    ArchiveBoxIcon,
    CogIcon
  },
  props: {
    parameters: Array
  },
}
</script>
