<template>
  <div>
    <div class="flex justify-between items-center">
      <h3 class="font-bold text-gray-500 text-xl">{{$t("device.history")}}:</h3>
      <div class="flex space-x-4 items-center">
        <p v-for="color in Object.values(colors)" :key="color.label" class="text-sm"><span :class="color.color" class="inline-block rounded-full w-2 h-2"></span> - {{ color.label }}</p>
      </div>
    </div>
    <div v-if="history && history.length" class="flex flex-col mt-6">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 pb-10">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 relative">
              <thead class="bg-gray-100 sticky top-0">
                <tr>
                  <th v-for="(column) in columns" :key="column.name" scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ column.label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(event, index) in history" :key="event.id" :class="index % 2 === 0 ? 'bg-gray-50' : '' " class="align-top">
                  <td 
                    v-for="column in columns" 
                    :key="column.name" 
                    class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900" 
                    v-html="column.processor(event)"
                  >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-gray-500 mt-2">{{$t("device.noHistory")}}.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryTable',
  props: {
    history: {
      type: Array,
      required: true,
    },
    parametersSchema: {
      type: Array,
      required: true
    }
  },  
  data() {
      return {
          // Your component's data goes here
      };
  },
  computed: {
    columns () {
      return [
        { label: this.$t('event.createdAt'), processor: this.dateProcessor},
        { label: this.$t('event.user'), processor: this.userProcessor},
        { label: this.$t('event.type'), processor: this.typeProcessor},
        { label: this.$t('event.details'), processor: this.detailsProcessor}
      ]
    },
    colors () {
      return {
        device: {color: 'bg-blue-500', label: this.$t('event.device.label')},
        installation: {color: 'bg-green-500', label: this.$t('event.installation.label')},
        inspection: {color: 'bg-yellow-500', label: this.$t('event.inspection.label')},
        service: {color: 'bg-red-500', label: this.$t('event.service.label')},
      }
    }
  },
  methods: {
      dateProcessor (event) {
        const { createdAt } = event;
        return new Date(createdAt).toLocaleString();
      },
      userProcessor (event) {
        const { user } = event;
        return user ? `<a href="${user.email}">${user.name} (${user.company})</a>` : '-'
      },
      typeProcessor (event) {
        const { type } = event;
        let bg = this.colors[type.split('.')[0]].color

        return `<span class="inline-block mr-1 w-2 h-2 rounded-full ${bg}"></span> ${this.$t(`event.${type}`)}`
      },
      detailsProcessor (event) {
        const { type, details } = event;
        
        let template
        let param
        let label
        const dateKeys = ['installationDate', 'executionDate', 'sparePartsSentDate']
        switch (type){
          case 'device.updated':
            param = this.parametersSchema.find(param => param.name === details.parameter)
            label = param.labels && param.labels[this.$i18n.locale] ? param.labels[this.$i18n.locale] : param.label            

            template = `${this.$t('device.parameter')}: <strong>${label}</strong>`
            break;
          case 'installation.created':
            template = `<a href="#installation-${details.id}">ID: <strong>${details.id}</strong></a>`
            break;
          case 'installation.receiver.added':
          case 'installation.receiver.removed':
            template = `${this.$t('event.user')}: <strong>${details.receiver}</strong>`
            break;
          case 'installation.updated':
          case 'inspection.updated':
          case 'service.updated':
            template = `<a href="#${type.split('.')[0]}-${details.id}">ID: <strong>${details.id}</strong><br />`
            template += Object.entries(details.changes)
            .filter(([key,value]) => !dateKeys.includes(key) || !value.old || value.old.date !== value.new.date)
            .map(([key,value]) => {
              const isDate = dateKeys.includes(key)
              let oldValue = isDate && value.old ? new Date(value.old.date).toLocaleDateString() : value.old
              let newValue = isDate && value.new ? new Date(value.new.date).toLocaleDateString() : value.new

              const isStatus = key === 'status'
              oldValue = isStatus ? this.$t(`${oldValue}`) : oldValue
              newValue = isStatus ? this.$t(`${newValue}`) : newValue

              return `${this.$t(`template.${key}`)}: <strong>${oldValue ? oldValue : '-'} -> ${newValue ? newValue : '-'}</strong>`
            }).join('<br />')
            template += '</a>'
            break;
          case 'inspection.created':
          case 'service.created':
            template = `<a href="#${type.split('.')[0]}-${details.id}">ID: <strong>${details.id}</strong></a><br />`
            break;
          default:
            template = '-'
        }
        
        return template
      },  
  },
  mounted() {
      // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
/* Your component's styles go here */
</style>