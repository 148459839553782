<template>
  <div class="border-b border-gray-200 sticky top-16 z-10 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <nav class="-mb-px flex justify-between">
        <div class="flex items-center">
          <input v-model="sentAtEnd" type="checkbox" class="rounded text-ifgreen focus:ring-ifgreen" />
          <label class="ml-2 text-sm">Wysłane na końcu</label>
          <span class="whitespace-nowrap text-sm ml-1">| wszystkie: {{ filteredDevices ? filteredDevices.length : '-' }}, wysłane: {{ sentDevicesLength }}</span>
        </div>
        <div class="flex items-center">
          <DeviceDateFilter @filter="filterDevices" class="mx-2" />
          <SortSelect :filteredByStep="filteredByStep" @select="handleSort" class="py-2 mr-2 w-36 flex-shrink-0" />
          <PropertySearch v-model="search" :options="searchParams" @search="searchDevices" placeholder="wyszukaj" classes="py-2 md:max-w-sm" />
        </div>
      </nav>
    </div>
  </div>
  <div class="mx-auto sm:px-6 lg:px-8">
    <div v-if="filteredDevices && filteredDevices.length" class="flex flex-col mt-6">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 pb-10">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 relative">
              <thead class="bg-gray-50 sticky top-0">
                <tr>
                  <th v-for="(label, index) in columnsLabels" :key="label" scope="col" :class="index == columnsLabels.length - 1 && 'text-right'" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(device, index) in filteredDevices" :key="device.id" :class="`${$filters.colorizeDevice(device, index)} ${device.parameters.wersja && device.parameters.wersja.slice(-1) === '*' && 'border border-yellow-600'}`">
                  <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <input type="checkbox" class="rounded text-ifgreen focus:ring-ifgreen" :checked="device.deviceExtension.sent" @change="updateSent($event, device.id)" />
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900" :title="device.parameters['numer_seryjny']">
                    <a target="_blank" class="underline hover:no-underline" :href="`/admin/devices/${device.id}`" >...{{ device.parameters['numer_seryjny'] && device.parameters['numer_seryjny'].slice(-10) }}</a>
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    <img :src="`/img/icons/${device.organisation.id}/favicon.ico`" class="h-5 w-5" />
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ device.deviceExtension.step }}
                  </td>
                  <td style="max-width: 120px" class="px-4 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis text text-sm text-gray-500" :title="stepSchema[device.deviceExtension.step].substeps.length > device.deviceExtension.substep ? stepSchema[device.deviceExtension.step].substeps[device.deviceExtension.substep].title : 'Zakończono'">
                    {{ stepSchema[device.deviceExtension.step].substeps.length > device.deviceExtension.substep ? stepSchema[device.deviceExtension.step].substeps[device.deviceExtension.substep].title : 'Zakończono' }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ device.parameters['model_szafy'] }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ device.parameters['pompa_ciepla'] }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ device.parameters['wersja'] }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ device.parameters['bypass'] }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500 space-x-1">
                    <span v-if="device.installation" title="Uruchomione" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                      Uruch.
                    </span>
                    <span v-if="!device.deviceExtension" title="Dodane przez instalatora" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                      Inst.
                    </span>
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    <input @change="updateOrderNo($event, device.id)" style="min-width: 200px" class="p-1 w-32 border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm" :value="device.deviceExtension.orderNo" />
                  </td>
                  <td class="px-4 py-4 space-x-2 whitespace-nowrap text-sm text-gray-500 flex justify-end">
                    <ButtonDropdown
                      :params="getDeviceParams(device)"
                    />
                    <ButtonDropdown
                      :links="getPhotoLinks(device.deviceExtension)"
                    />
                    <ButtonDropdownLogTable :extension-id="parseInt(device.deviceExtension.id)" />
                    <ButtonDropdownNotes :device="device" @update-note="updateNote" />
                    <button title="Edytuj urządzenie" @click="openDeviceParamsModal(device)" class="rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                      <CogIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <p v-if="!query.nextPage" class="pb-36 text-center">Załadowano wszystkie dane dla danych parametrów wyszukiwania.</p>
    </div>
    <p class="mt-6" v-if="filteredDevices && filteredDevices.length === 0">Nie znaleziono urządzenia spełniającego kryteria wyszukiwania</p>
  </div>
  <EditDevice
    ref="editDeviceModal"
    :device="editDevice"
    :production="true"
    @edit-device-params="updateDeviceParams"
  />
</template>

<script>
//import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/vue/24/outline'
import ButtonDropdown from "@/components/admin/ButtonDropdown.vue"
import ButtonDropdownLogTable from "@/components/admin/ButtonDropdownLogTable.vue"
import ButtonDropdownNotes from "@/components/admin/ButtonDropdownNotes.vue"
import PropertySearch from '@/components/inputs/PropertySearch.vue'
import SortSelect from '@/components/admin/SortSelect.vue'
import DeviceDateFilter from '@/components/admin/DeviceDateFilter.vue'
import EditDevice from '@/components/admin/EditDevice.vue'
import stepSchema from "@/assets/production-steps.json"
import { CogIcon, CheckIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { mapState } from 'vuex'
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { attachTemplate, serializeParams, deserializeParams } from '@/assets/js/helpers.js'

export default {
  name: "AdminDevices",
  components: {
    ButtonDropdown,
    ButtonDropdownLogTable,
    ButtonDropdownNotes,
    PropertySearch,
    SortSelect,
    DeviceDateFilter,
    EditDevice,
    CogIcon,
    CheckIcon,
    ExclamationTriangleIcon
    //rrowLeftIcon,
    //CheckCircleIcon,
  },
  data () {
    return {
      devices: null,
      searchString: '',
      editDevice: null,
      sortColumn: 'createdAt',
      sentAtEnd: true,
      query: {
        url: null,
        nextPage: null,
        loading: false
      },
      filteredByStep: 1,
      search: {
        param: null,
        value: ''
      }
    }
  },
  computed: {
    ...mapState(['specialDeviceTemplates']),
    stepSchema () {
      return stepSchema
    },
    columnsLabels () {
      return ["Wysłano", "Numer seryjny", "Marka", "Etap", "Podetap", "Model szafy", "Pompa ciepła", "Wersja", "Bypass", "Status", "Nr zamówienia", "Więcej"]
    },
    filteredDevices () {
      if (!this.searchString) {
        return this.devices
      }
      else {
        return this.devices.filter((device) => {
          const order_no = device.deviceExtension.orderNo && device.deviceExtension.orderNo.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1
          const params = device.parameters && Object.values(device.parameters).some(param => {
            return param && param.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1})
          return order_no || params
        })
      }
    },
    sentDevicesLength () {
      return this.filteredDevices ? this.filteredDevices.filter(device => device.deviceExtension.sent).length : '-'
    },
    searchParams() {
      const template = this.specialDeviceTemplates && this.specialDeviceTemplates.find(template => {return template.organisation.id == 1})
      const searchParams = template ? 
        template.activeVersion.parametersSchema.map(param => {return {name: param.label, value: param.name}}) 
        : []
      return [{name: 'Nr zamówienia', value: 'orderNo'}, ...searchParams]
    }
  },
  async created () {
    this.$store.dispatch('setLoading', true)
    if (!this.specialDeviceTemplates) await this.$store.dispatch('getSpecialDeviceTemplates')
    this.query.url = new URL('/custom_devices', 'http://base.com')
    this.query.url.searchParams.set('order[deviceExtension.createdAt]', "desc")
    this.query.url.searchParams.set('exists[deviceExtension]', "true")
    this.query.loading = true
    console.log(`${this.query.url.pathname}${this.query.url.search}`)
    const result = await api.get(`${this.query.url.pathname}${this.query.url.search}`)
    if (result.data.length == 100) this.query.nextPage = 2
    this.devices = result.data.map(device => {return serializeParams(device)})
    console.log(this.devices)
    this.handleSort()
    this.query.loading = false
    window.addEventListener('scroll', this.handleScroll)
    this.$store.dispatch('setLoading', false)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    async loadMore() {
      if (!this.searchString && this.query.nextPage && !this.query.loading) {
        this.$store.dispatch('setLoading', true)
        this.query.loading = true
        this.query.url.searchParams.set('page',this.query.nextPage)
        const result = await api.get(`${this.query.url.pathname}${this.query.url.search}`)
        result.data.length == 100 ? this.query.nextPage++ : this.query.nextPage = null
        this.devices.push(...result.data.map(device => {return serializeParams(device)}))
        this.handleSort()
        this.query.loading = false
        this.$store.dispatch('setLoading', false)
      }
    },
    handleScroll() {
      if (document.body.offsetHeight && window.scrollY + window.innerHeight >= document.body.offsetHeight) {
        this.loadMore()
      }
    },
    handleSort (sortColumn) {
      if (sortColumn) this.sortColumn = sortColumn
      if (this.sentAtEnd) {
        this.devices = this.devices.sort((a, b) => {
          if (+a.deviceExtension.sent === +b.deviceExtension.sent) { // + casts true/false to 1/0
            if (new Date(a.deviceExtension[this.sortColumn]).getTime() < new Date(b.deviceExtension[this.sortColumn]).getTime()) return 1
            else if (new Date(a.deviceExtension[this.sortColumn]).getTime() > new Date(b.deviceExtension[this.sortColumn]).getTime()) return -1
            else return 0
          }
          else {
            return +a.deviceExtension.sent > +b.deviceExtension.sent ? 1 : -1
          }
        })
      } else {
        this.devices = this.devices.sort((a, b) => {
          if (new Date(a.deviceExtension[this.sortColumn]).getTime() < new Date(b.deviceExtension[this.sortColumn]).getTime()) return 1
          else if (new Date(a.deviceExtension[this.sortColumn]).getTime() > new Date(b.deviceExtension[this.sortColumn]).getTime()) return -1
          else return 0
        })
      }
    },
    async searchDevices () {
      this.$store.dispatch('setLoading', true)
      this.query.loading = true
      this.query.url.searchParams.delete('page')
      if (this.search.value) {
        if (this.search.param == "orderNo") {
          this.query.url.searchParams.set('deviceExtension.orderNo', this.search.value)
          this.query.url.searchParams.delete('parameters[partial]')
        } else {
          this.query.url.searchParams.delete('deviceExtension.orderNo')
          this.query.url.searchParams.set(`parameter[${this.search.param}]`, this.search.value)
        }
      } else {
        this.query.url.searchParams.delete('deviceExtension.orderNo')
        this.searchParams.forEach(param => {
          this.query.url.searchParams.delete(`parameter[${param.value}]`)
        })
      }
      const result = await api.get(`${this.query.url.pathname}${this.query.url.search}`)
      this.devices = result.data.map(device => {return serializeParams(device)})
      result.data.length == 100 ? this.query.nextPage = 2 : this.query.nextPage = null
      console.log(result.data)
      this.handleSort()
      this.query.loading = false
      this.$store.dispatch('setLoading', false)
    }, 
    filterDevices (e) {
      this.$store.dispatch('setLoading', true)
      this.filteredByStep = e['deviceExtension.deviceLogs.movedToStep']
      this.query.loading = true
      this.query.url.searchParams.delete('page')
      Object.entries(e).forEach((entry) => {
        this.query.url.searchParams.set(entry[0], entry[1])
      })
      api.get(`${this.query.url.pathname}${this.query.url.search}`).then((result) => {
        result.data.length == 100 ? this.query.nextPage = 2 : this.query.nextPage = null
        this.devices = result.data
        this.handleSort()
        this.query.loading = false
        this.$store.dispatch('setLoading', false)
      }).catch(err => {
        this.$store.dispatch('setLoading', false)
        this.query.loading = false
        console.log(err);
      })
    },
    getPhotoLinks (device) {
      const links = [
        {name: "hydraulika", url: device.hydraulicsImage},
        {name: "manometry", url: device.manometersImage},
        {name: "manometry 2", url: device.manometers2Image},
        {name: "termometr", url: device.termometerImage},
        {name: "manometr cwu", url: device.dhwManometerImage},
        {name: "manometr co", url: device.heatingManometerImage},
        {name: "szafa z założonymi wężami", url: device.hosesInstalledImage},
        {name: "elektryka", url: device.electricsImage},
        {name: "elektryka 2", url: device.electrics2Image},
        {name: "Zawartość opakowania", url: device.boxContentImage},
        {name: "szafa - lewa strona", url: device.fromLeftImage},
        {name: "szafa - prawa strona", url: device.fromRightImage},
        {name: "szafa - spakowana", url: device.packedImage},
        {name: "Komplet zawartości opakowania", url: device.completImage},
        {name: "etykieta wysyłki", url: device.deliveryLabelImage}
      ]
      return links.filter((link) => {return link.url})
    },
    getDeviceParams (device) {
      device = attachTemplate(device, true)
      return device.schemaVersion ? device.schemaVersion.parametersSchema.map(field => {
        return {label: field.label, value: device.parameters[field.name]}
      }).filter(param => {return param.value}) : []
    },
    updateOrderNo(e, id){
      const device = {
        deviceExtension: {
          orderNo: e.target.value
        }
      }
      this.updateDevice (id, device)
    },
    updateSent(e, id){
      const device = {
        deviceExtension: {
          sent: e.target.checked
        }
      }
      this.updateDevice (id, device)
    },
    openDeviceParamsModal (device) {
      this.editDevice = attachTemplate(device, true)
      this.$nextTick(() => {
        this.$refs.editDeviceModal.show()
      })
    },
    updateDeviceParams (e) {
      setTimeout(() => { this.$store.dispatch('setLoading', true) }, 100)
      this.updateDevice (e.id, e.device)
    },
    updateNote(e){
      const device = {
        deviceExtension: {
          note: e.value
        }
      }
      this.updateDevice (e.id, device)
    },
    updateDevice (id, device) {
      device = deserializeParams(device)
      api.patch(`/custom_devices/${id}`, device, patchHeaders).then(result => {
        console.log(result)
        device = serializeParams(result.data)
        const index = this.devices.findIndex((device) => {return device.id === id})
        device.deviceExtension = Object.assign({}, this.devices[index].deviceExtension, device.deviceExtension)
        this.devices[index] = Object.assign({}, this.devices[index], device)
        console.log(this.devices[index])
        this.$nextTick(() => this.handleSort())
        this.$store.dispatch('setLoading', false)
      }).catch(err => {
        console.log(err)
        this.$store.dispatch('setLoading', false)
      })
    }
  },
  watch: {
    sentAtEnd () {
      this.handleSort()
    },
    search: {
      deep: true,
      handler() {
        if (!this.search.param) {
          this.searchString = this.search.value
        } else {
          this.searchString = ""
        }
      }
    }
  }
};
</script>
